import React from "react";

export default function(fieldConfiguration, inputField) {
    let classes = "form-group row ";
    let helpText = "";
    let helpTextClasses = "form-text m-b-none ";

    if(fieldConfiguration.error) {
        classes += "has-error";
        helpTextClasses += "text-danger";

        helpText = fieldConfiguration.error;
    } else if(fieldConfiguration.warning) {
        classes += "has-warning";
        helpTextClasses += "text-warning";

        helpText = fieldConfiguration.warning;
    } else if(fieldConfiguration.success) {
        classes += "has-success";
        helpTextClasses += "text-success";
        
        helpText = fieldConfiguration.success;
    }
    
    let labelSize;
    let fieldSize;

    if(fieldConfiguration.inlineLabels === true) {
        if(fieldConfiguration.inlineLabelSize && Number.isInteger(fieldConfiguration.inlineLabelSize)) {
            labelSize = fieldConfiguration.inlineLabelSize;
        } else {
            labelSize = 2;
        }

        fieldSize = 12 - labelSize;
    } else {
        labelSize = 12;
        fieldSize = 12;
    }    

    return (
        <div className={classes} key={`label_${fieldConfiguration.key}`}>
            <label htmlFor={`${fieldConfiguration.id}`} id={`${fieldConfiguration.id}-label`} className={`col-lg-${labelSize} col-form-label ${helpTextClasses}`}>
                {fieldConfiguration.label}
                <span id={`${fieldConfiguration.id}-required`}>{fieldConfiguration.required === true ? " *" : ""}</span>
            </label>

            <div className={`col-lg-${fieldSize}`}>
                {inputField}
                <span id={`${fieldConfiguration.id}-help-text`} className={helpTextClasses}>{helpText}</span>
            </div>
        </div>
    )
}

