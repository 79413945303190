import fetchRequiredValue from "./fetchRequiredValue";
import recursiveRender from "./RecursiveRender";

var jsont = require("jsont")();
var render = require("ejs").render;
var moment = require("moment");

var DataDelegator = require("./DataDelegator");

moment.defineLocale("en-robostack", {
    parentLocale: "en",
    invalidDate: "never", // so that fromNow() will return never if invalid when used by momentify
});

// Search an array of objects and return an object whose given key matches the given value
jsont.use("search-array-for-object", function (arr, key, value, cb) {
    if (Array.isArray(arr)) {
        cb(null, arr.filter((currentObj) => currentObj[key] == value));
    } else {
        cb(null, arr);
    }
})

// Return the length of the given input
jsont.use("length", function (input, cb) {
    cb(null, input.length);
});

// Trim the string
jsont.use("trim", function (obj, key, cb) {
    if (typeof obj[key] == "string") {
        obj[key] = obj[key].trim();
    }

    cb(null, obj);
});

// Sort the array by a given property and return it
// Supports sort order
jsont.use("sort", function (input, property, cb) {
    let sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    input.sort((a, b) => {
        if (a[property] === null) {
            return 1; // move `a` to a higher index
        } else if (b[property] === null) {
            return -1; // move `b` to a higher index
        } else {
            let result;

            if (typeof a[property] == "number" && typeof b[property] == "number") {
                result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            } else {
                result = ("" + a[property]).localeCompare(b[property]);
            }

            return result * sortOrder;
        }
    });

    cb(null, input);
});

// Return a prop from the input object
jsont.use("prop", function (obj, prop, cb) {
    if (obj != undefined && obj !== null) {
        cb(null, obj[prop]);
    } else {
        cb(null, obj);
    }
});

jsont.use("deep-clone", function (obj, cb) {
    cb(null, JSON.parse(JSON.stringify(obj)));
});

// Apply momentjs on a prop
jsont.use("momentify", function (obj, key, cb) {
    let withoutTimezone = moment(obj[key]).format("YYYY-MM-DD HH:mm:ss");

    obj[key] = moment.utc(withoutTimezone).local().fromNow();
    cb(null, obj);
});

// Apply momentjs on a prop
jsont.use("moment-format", function (obj, key, format, cb) {
    obj[key] = moment(obj[key]).format(format);

    cb(null, obj);
});

// Convert the numbers into comma separated values
jsont.use("locale-string", function (value, cb) {
    value = value.toLocaleString('en-IN');

    cb(null, value);
});

jsont.use("wrap-in-array", function (value, cb) {
    value = [value];

    cb(null, value);
});


jsont.use("reduce-to-key-value", function (obj, key, value, cb) {
    if (Array.isArray(obj)) {
        const reduced = obj.reduce((acc, currentObject) => {
            let currentKey = key;
            let currentValue = value;

            if (currentKey[0] == "$") {
                currentKey = currentObject[currentKey.substr(1)] || null;
            }

            if (currentValue[0] == "$") {
                currentValue = currentObject[currentValue.substr(1)] || null;
            }

            if (currentValue[0] == "#") {
                currentValue = JSON.parse(currentValue.substr(1));
                currentValue = recursiveRender(currentValue, currentObject);
            }

            acc[currentKey] = currentValue;

            return acc;
        }, {});

        cb(null, reduced);
    } else {
        cb(null, obj);
    }
});

jsont.use("to-fixed", function (input, key, precision, cb) {
    const value = parseFloat(input[key]);
    if (!isNaN(value)) {
        input[key] = value.toFixed(precision);
    }

    cb(null, input);
});

jsont.use("to-locale-string", function (input, key, locale, cb) {
    const value = parseFloat(input[key]);
    if (!isNaN(value)) {
        input[key] = (input[key]).toLocaleString(locale);
    }

    cb(null, input);
});

// Convert a non object ot an object with the given key
// Returns the same input without modifications if it is already an object
jsont.use("to-object", function (input, key, cb) {
    if (typeof input != "object") {
        input = {
            [key]: input
        };
    }

    cb(null, input);
});

jsont.use("remove-all-keys-except", function (input, keysAsString, cb) {
    let toReturn = input;
    const keys = keysAsString.split(",");

    if (typeof input == "object") {
        toReturn = Object.keys(toReturn).reduce((acc, key) => {
            if (keys.includes(key)) {
                acc[key] = toReturn[key];
            }
            return acc;
        }, {});
    }

    cb(null, toReturn);
});

jsont.use("to-empty-object", function (input, key, cb) {
    if (typeof input != "object") {
        input = {
            [key]: {},
        };
    }

    cb(null, input);
});

// Set a key-value on the input object
jsont.use("set-prop", function (obj, key, value, cb) {
    value = fetchRequiredValue(obj, value);

    try {
        if (typeof value === "string") {
            value = render(value, obj);
        }
        obj[key] = value;
    } catch (err) {
        console.error(err);
    }

    cb(null, obj);
});

// Join an array field using the given characters
jsont.use("join", function (obj, key, value, cb) {
    if (Array.isArray(obj[key])) {
        obj[key] = obj[key].join(value);
    }

    cb(null, obj);
});

// Set a key-value on the input object
jsont.use("set-prop-if-not-exists", function (obj, key, value, cb) {
    value = fetchRequiredValue(obj, value);

    if (obj[key] == undefined) {
        try {
            if (typeof value === "string") {
                value = render(value, obj);
            }
            obj[key] = value;
        } catch (err) {
            console.error(err);
        }
    }

    cb(null, obj);
});

// Set a key-value on the input object from the store
jsont.use("set-prop-from-store", function (obj, key, value, cb) {
    value = DataDelegator.getFromStore(key);

    obj[key] = value;
    cb(null, obj);
});

// Remove a key from the given object
jsont.use("unset-prop", function (obj, key, cb) {
    delete obj[key];
    cb(null, obj);
});

// Add props to every sub object in the given object
jsont.use("add-prop-to-all-nested-objects", function (obj, key, value, cb) {
    if (typeof obj == "object") {
        Object.keys(obj).forEach((subObjKey) => {
            obj[subObjKey][key] = fetchRequiredValue(obj[subObjKey], value);
        });
    }

    cb(null, obj);
});

// Return the first element of an array or null
jsont.use("pop", function (arr, cb) {
    if (Array.isArray(arr) && arr.length > 0) {
        return cb(null, arr[0]);
    }

    return cb(null, null);
});

jsont.use("key-value-to-object", function (obj, valueKey, cb) {
    const newObj = Object.keys(obj).reduce((acc, currentValue) => {
        acc[currentValue] = {
            [valueKey]: obj[currentValue],
        };

        return acc;
    }, {});

    cb(null, newObj);
});

// Set the prop value to either of the following based on truthiness
jsont.use("set-prop-this-or-that", function (obj, key, value1, value2, cb) {
    value1 = fetchRequiredValue(obj, value1);
    value2 = fetchRequiredValue(obj, value2);

    obj[key] = value1 || value2;
    cb(null, obj);
});

jsont.use("or", function (obj, key, value, cb) {
    let newValue;

    if (typeof obj != "object") {
        newValue = obj || key;

        if (cb == undefined) {
            cb = value;
        }
    } else {
        obj[key] = obj[key] || value;
        newValue = obj;
    }

    cb(null, newValue);
});

jsont.use("group-by-key", function (obj, keyToGroupBy, groupStructure, dataKeyToStoreUnder, groupInvalidValuesUnder, cb) {
    obj = obj.reduce((acc, item) => {
        // Group by key
        let value = item[keyToGroupBy];

        if(groupInvalidValuesUnder) {
            if(value == undefined || value == null) {
                value = groupInvalidValuesUnder;
            }
        }

        if (!acc[value]) {
            acc[value] = JSON.parse(groupStructure);

            // Find all the values in the structure and then replace the values as needed
            Object.keys(acc[value]).forEach(key => {
                if (typeof acc[value][key] == "string") {
                    acc[value][key] = ejs.render(acc[value][key], {
                        ...item,
                        [keyToGroupBy]: value, // So that we can handle the case where the value is undefined or null and we want to group it under a different value
                    });
                }
            });
        }

        if (dataKeyToStoreUnder != null) {
            acc[value][dataKeyToStoreUnder].push(item);
        } else {
            acc[value].push(item);
        }

        return acc;
    }, {});

    cb(null, obj);
});

jsont.use("object.values", function(obj, cb) {
    cb(null, Object.values(obj));
});

jsont.use("filter-by-key", function(obj, key, value, cb) {
    cb(null, obj.filter(item => item[key] == value));
});

jsont.use("filter-key-from-stringified-values", function(obj, key, values, cb) {
    cb(null, obj.filter(item => values.includes(item[key])));
});

/*
   * @param `data` The data that is meant to be transformed into the given template
   * @param `template` The template that the given data will be transformed into
*/
export function transform(data, template) {
    return new Promise((resolve, reject) => {
        jsont.render(template, data, (err, out) => {
            if (err) return reject(err);

            return resolve(out);
        });
    });
}
