import React, { useEffect, useState } from "react";

export default function AddSearchToComponent(props) {
    const [searchValue, setSearchValue] = useState("");
    const [data, setData] = useState([]);

    const onSearchChange = (event) => {
        setSearchValue(event.target.value);
    }

    useEffect(() => {
        setData(props.data);
    }, props.data);


    useEffect(() => {
        const value = searchValue;

        const newData = [...props.data]
            .map((item) => {
                item.hidden = (
                    value.length > 0 &&
                    Array.isArray(props.searchKeys) &&
                    props.searchKeys.every((search) => {
                        return item[search] !== undefined && item[search] !== null && item[search].toLowerCase().indexOf(value) == -1;
                    })
                );
                return item;
            });

        setData(newData);
    }, [searchValue, props.data]);

    if (!Array.isArray(props.data) || props.data.length == 0) {
        return <>No data found</>
    }

    const searchedData = data.filter((tuple) => !tuple.hidden);

    return (
        <>
            <div className={`row ${props.className}`}>
                <div className="col-sm-12">
                    <div className="form-group">
                        <input
                            autoFocus={props.autoFocus}
                            onChange={onSearchChange}
                            placeholder={props.searchPlaceholder}
                            className="form-control form-control-lg search"
                            type="text"
                        />
                    </div>
                </div>

                {searchedData.length == 0 ?
                    <div className="col-sm-12">
                        Nothing found for <em>{searchValue}</em>
                    </div>
                    :
                    searchedData.map((tuple) => {
                        return props.render(tuple);
                    })}
            </div>
        </>
    )
}