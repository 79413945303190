import React from "react";

import Select from "react-select";

import removeCustomProps from "../../removeCustomProps";
import defaultUpdateProps from "../../defaultUpdateProps";

export const defaultValidationMessages = {
    required: "This is a required value",

    notFound: "The provided existing value was not found in the given options"
}

// The purpose of this function is to format the value as needed for the field type in the event it needs to be set exteranlly for any reason
export function formatValue({ fieldConfiguration, value }) {
    let formattedValue = value;

    return formattedValue;
}

// The process function will validate the value and then return if it is valid or not along with the value itself
export function process({ fieldConfiguration, value: selectedOption }, callbackFn) {
    let value = selectedOption;

    if(selectedOption !== undefined && selectedOption !== null) {
        if(fieldConfiguration.isMulti === true) {
            value = selectedOption.map((option) => {
                if(typeof option == "object") {
                    return option.value;
                }

                return option;
            })

        } else if(typeof selectedOption == "object") {
            value = selectedOption.value;
        }
    }

    validate(fieldConfiguration, value, (err, updateProps) => {
        if (err) {
            callbackFn(err);
        } else {
            const hasError = updateProps.error !== undefined && updateProps.error.length > 0;

            callbackFn(null, {
                valid: hasError === false,
                fieldConfiguration,
                value,
            })
        }
    })
}

export function validate(fieldConfiguration, selectedOption, callbackFn) {
    const value = typeof selectedOption == "object" && selectedOption !== null ? selectedOption.value : selectedOption;

    let updateProps = defaultUpdateProps(fieldConfiguration);

    if (["", undefined].includes(value)) {
        if (fieldConfiguration.required === true) {
            updateProps.error = defaultValidationMessages.required;
        }
    } else {
        // Check if the given value is in the options
        if (Array.isArray(fieldConfiguration.options)) {
            const found = fieldConfiguration.options.filter((currentObject) => {
                if (currentObject.label !== undefined && Array.isArray(currentObject.options)) {
                    // This is the case where there might be options grouped under a label
                    return currentObject.options.filter((option) => option.value == value).length > 0;
                } else {
                    return currentObject.value == value;
                }
                
            });

            if (found.length == 0) {
                updateProps.error = defaultValidationMessages.notFound;
            }
        }

    }

    if (typeof callbackFn == "function") {
        callbackFn(null, updateProps);
    }
}

export function render(fieldConfiguration) {
    console.log("dropdown render");

    // We only want to consider `value` as the official value to avoid any confusions
    delete fieldConfiguration.defaultValue;

    let props = {
        ...fieldConfiguration,

        // We do not want form-control with this dropdown because it does not gel well with react-select's styles
        className: fieldConfiguration.classes.filter(className => className != "form-control").join(" "),
    };

    /**
     *? Check if there is an error here so that we can style the component as needed
     *? This is usually handled by bootstrap for normal fields using the label and css
     *? but because this is using another component, we have to handle it here
     */
    if (fieldConfiguration.error !== undefined && fieldConfiguration.error !== "") {
        props.styles = {
            control: (base, state) => ({
                ...base,
                border: '1px solid #ed5565',
            })
        }
    }

    if (props.sortOptions === true) {
        //? Sort the object if there is a display key only otherwise it remains unsorted
        if (typeof props.options[0] === "object") {
            if (props.options[0].display !== undefined) {
                props.options.sort((a, b) => {
                    return ('' + a.display).localeCompare(b.display)
                });
            }
        } else {
            props.options.sort();
        }
    }

    props.isDisabled = props.disabled;

    props.placeholder = props.placeholder || "Select an option";
    props.getOptionValue = (option) => typeof option !== "object" ? option : option.value;
    props.getOptionLabel = (option) => typeof option !== "object" ? option : option.display;

    props.isMulti = props.isMulti === true;

    props.classNamePrefix = "react-select";

    props.onChange = (selectedOption) => {
        let value = selectedOption;

        if(selectedOption !== undefined && selectedOption !== null) {
            if(props.isMulti === true) {
                value = selectedOption.map((option) => {
                    if(typeof option == "object") {
                        return option.value;
                    }

                    return option;
                })

            } else if(typeof selectedOption == "object") {
                value = selectedOption.value;
            }
        }

        validate(fieldConfiguration, value, (err, updateProps) => {
            updateProps.manuallyChanged = true;

            fieldConfiguration.onChange(selectedOption, {
                ...updateProps,

                forceUpdate: true, // Because we depend on the value for rendering the react-select component to show the currently selected option
            });
        })

        return false;
    }

    // Get the correct display value from options
    if (props.value !== undefined && Array.isArray(props.options)) {
        // Check if we are working with one value or multiple
        if(props.isMulti === true && Array.isArray(props.value)) {
            // Iterate over the values and find the corresponding object in props.options
            props.value = props.value.map((value) => {
                let valueToCompare = typeof value == "object" ? value.value : value;

                const found = props.options.filter((currentObject) => currentObject.value == valueToCompare);

                // If there are multiple values found, take the first one only
                if (found.length > 0) {
                    return found.pop();
                }

                return undefined;
            }).filter((value) => value !== undefined);
        } else if(typeof props.value !== "object") {
            const found = props.options.filter((currentObject) => currentObject.value == props.value);

            // If there are multiple values found, take the first one only
            if (found.length > 0) {
                props.value = found.pop();
            }
        }
    }

    const showAddOptionForm = () => {
        console.log("dropdown showAddOptionForm");

        props.showAddOptionForm();
    }

    //? We do not removeCustomProps here because the Select component will handle that for us

    if (props.addOption) {
        return <div className="input-group">
            <Select {...props} />
            <span className="input-group-append">
                <button
                    disabled={props.disabled}
                    onClick={showAddOptionForm}
                    title="Add New"
                    className="btn btn-default"
                    type="button">
                    <i className="fas fa-plus"></i>
                </button>
            </span>
        </div>
    }

    return <Select {...props} />;
}