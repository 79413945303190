import React, { useEffect, useState } from "react";
import { Link, useLoaderData, useRevalidator } from "react-router-dom";

import axiosBackend from "../core/api/backend";

import ImageWithFallback from "../components/root/ImageWithFallback";
import setWindowTitle from "../core/utils/setWindowTitle";

import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import steps from "./help-steps";
import ShouldIntroductionTourRun from "../core/onboarding/should-introduction-tour-run";
import MarkIntroductionTourDone from "../core/onboarding/mark-introduction-tour-done";

import { connect } from "react-redux";
import { mapStateToProps } from "../components/smart/delegator/DataDelegator";


const introductionIdentifier = "applications/index";

export async function loader({ params }) {
    const [sites, labels, shouldRun] = await Promise.all([
        axiosBackend.post("/apps"),
        axiosBackend.post("/apps/labels"),
        ShouldIntroductionTourRun({ identifier: introductionIdentifier })
    ])

    return {
        list: sites.data,
        labels: labels.data.results,
        shouldRun,
    };
}

function Landing(props) {
    const revalidator = useRevalidator();
    const {
        list: loaderList,
        labels,
        shouldRun,
    } = useLoaderData();

    useEffect(() => {
        if (shouldRun) {
            setShowTour(true);
        }
    }, [shouldRun]);

    setWindowTitle("");

    const searchKeys = ["name", "uuid"];

    const [searchText, setSearchText] = React.useState("");
    const [searchResults, setSearchResults] = React.useState("");

    const search = React.useCallback((event) => {
        const value = event.target.value.trim().toLowerCase();
        setSearchText(value);

        if (loaderList.status == "success" && loaderList.results.length > 0) {
            const searchResultList = [...loaderList.results]
                .map((item) => {
                    item.hidden = (
                        value.length > 0 &&
                        searchKeys.every((search) => item[search] !== undefined && item[search] !== null && item[search].toLowerCase().indexOf(value) == -1)
                    );
                    return item;
                });

            setSearchResults(searchResultList);
        }

    }, [searchText]);

    let list;

    if (loaderList?.status == "success") {
        list = searchText == "" ? loaderList.results : searchResults;
    }

    const [showTour, setShowTour] = React.useState(false);
    const [stepIndex, setStepIndex] = React.useState(0);

    const toggleHelp = () => {
        setShowTour(true);
    }

    const handleJoyrideCallback = (data) => {
        if ([STATUS.FINISHED, STATUS.SKIPPED, STATUS.CLOSE].includes(data.status) || ACTIONS.CLOSE == data.action) {
            // Need to set our running state to false, so we can restart if we click start again.
            setShowTour(false);
            setStepIndex(0);

            if(shouldRun) {
                MarkIntroductionTourDone({ identifier: introductionIdentifier });
            }
        } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(data.type)) {
            // Update state to advance the tour
            setStepIndex(data.index + (data.action === ACTIONS.PREV ? -1 : 1));
        }
    }

    const labelsData = labels.reduce((acc, item) => {
        if (!acc.sitesMap[item.label_uuid]) {
            acc.sitesMap[item.label_uuid] = [];
        }

        acc.sitesMap[item.label_uuid].push(item.item_uuid);
        acc.sitesToSkip.push(item.item_uuid);

        acc.labelsMap[item.label_uuid] = item;

        return acc;
    }, {
        sitesMap: {},
        labelsMap: {},
        sitesToSkip: [],
    });

    const showAddLabel = ({ event, site }) => {
        event.preventDefault();

        props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                "show": true,
                "type": "data-collection",
                "title": "Add Label To Application - " + site.name,
                yesButtonText: "Add Label",
                noButtonText: "Cancel",
                modelBodyClasses: [],
                modalSize: "large",

                robostackResolveData: [
                    {
                        "name": "ListOfAllAvailableLabels",
                        "forceApiReload": true,
                        "api": {
                            "method": "POST",
                            "endpoint": "/apps/all-available-labels",
                            "data": {}
                        },
                        "transformMergeIfArray": true,
                        "transformMerge": true,
                        "transformArrayMergeStratergy": "overwriteTarget",
                        "transform": {
                            "fields": {
                                "label_uuid": {
                                    "options": "`data.results | sort:name | filter-key-from-stringified-values:organization_uuid,'[\"" + site.customerAccountUUID + "\", null]' | map | set-prop:display,$name | set-prop:value,$uuid | reduce | group-by-key:organization_name,'{\"label\":\"<%= organization_name %>\",\"options\": []}'options,'Personal Labels' | object.values`",
                                },
                            }
                        }
                    }
                ],
                showSubmitButton: false,
                fields: [
                    {
                        "label_uuid": {
                            "label": "What would you like to label this application?",
                            "placeholder": "Select an existing label or click on the + to create a new one",
                            "noOptionsMessage": () => "No labels found, click on the + to create a new one",
                            "type": "dropdown",
                            "required": true,
                            "displayPosition": 2,
                            "addOption": true,
                            "showAddOptionForm": () => {
                                props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        "show": true,
                                        "type": "data-collection",
                                        "title": "Add New Label",
                                        yesButtonText: "Add Label",
                                        noButtonText: "Cancel",
                                        modelBodyClasses: [],
                                        modalSize: "large",
                        
                                        robostackResolveData: [
                                            
                                        ],
                                        showSubmitButton: false,
                                        fields: [
                                            {
                                                "type": {
                                                    "label": "Who do you want this label visible to?",
                                                    "placeholder": "Select a label type",
                                                    "type": "dropdown",
                                                    "required": true,
                                                    "displayPosition": 1,
                                                    "options": [
                                                        {
                                                            "display": "Just Myself",
                                                            "value": "user"
                                                        },
                                                        {
                                                            "display": "The Entire Team",
                                                            "value": "organization"
                                                        }
                                                    ]
                                                },
                                                "name": {
                                                    "label": "Label Name",
                                                    "placeholder": "Enter a name for the label",
                                                    "type": "text",
                                                    "required": true,
                                                    "displayPosition": 2,
                                                },
                                            },
                                        ],
                        
                                        onYes: (onClose, tuple) => {
                                            props.dispatch({
                                                type: "ResolvedData",
                                                name: "ModalData",
                                                data: {
                                                    "show": true,
                                                    "type": "processing",
                                                    "title": "Adding Label",
                                                    "message": [
                                                        "This might take a few seconds.",
                                                        "You will be shown a confirmation screen once completed."
                                                    ]
                                                },
                                            });
                        
                                            setTimeout(() => {
                                                axiosBackend({
                                                    method: "PUT",
                                                    url: `/apps/labels`,
                                                    data: {
                                                        name: tuple.name,
                                                        type: tuple.type,
                                                        site_uuid: site.uuid,
                                                    }
                                                })
                                                    .then((response) => {
                                                        const result = response.data;
                        
                                                        revalidator.revalidate();
                        
                                                        props.dispatch({
                                                            type: "ResolvedData",
                                                            name: "ModalData",
                                                            data: {
                                                                show: true,
                                                                type: "success",
                                                                title: "Success",
                                                                message: [
                                                                    `The label "${tuple.name}" was successfully created`,
                                                                ],
                                                                okayButtonText: "Okay",
                                                                onClose: () => {
                                                                    showAddLabel({ event, site });
                                                                },
                                                            },
                                                        });
                                                    })
                                                    .catch((err) => {
                                                        console.error(err);
                                                        props.dispatch({
                                                            type: "ResolvedData",
                                                            name: "ModalData",
                                                            data: {
                                                                show: true,
                                                                type: "error",
                                                                title: "Could not add label",
                                                                message: [
                                                                    "Due to an error, we were unable to add the label you wanted to create",
                                                                    "‏‏‎ ‎",
                                                                    "Please try again in a little while."
                                                                ],
                                                                okayButtonText: "Okay"
                                                            },
                                                        });
                                                    })
                                            }, 1000);
                                        }
                                    },
                                });
                            },
                            "classes": ["flex-fill"],
                            "options": labels.map((label) => ({
                                "display": label.name,
                                "value": label.uuid
                            })),
                        },
                    },
                ],

                onYes: (onClose, tuple) => {
                    props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            "show": true,
                            "type": "processing",
                            "title": "Adding Label",
                            "message": [
                                "This might take a few seconds.",
                                "You will be shown a confirmation screen once completed."
                            ]
                        },
                    });

                    setTimeout(() => {
                        axiosBackend({
                            method: "PUT",
                            url: `/apps/${site.uuid}/labels/`,
                            data: {
                                label_uuid: tuple.label_uuid,
                            }
                        })
                            .then((response) => {
                                const result = response.data;

                                revalidator.revalidate();

                                props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "success",
                                        title: "Success",
                                        message: [
                                            `The new label was added successfully to ${site.name}`,
                                        ],
                                        okayButtonText: "Okay",
                                    },
                                });
                            })
                            .catch((err) => {
                                console.error(err);
                                props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "error",
                                        title: "Could not add label",
                                        message: [
                                            "Due to an error, we were unable to add the label you had selected",
                                            "‏‏‎ ‎",
                                            "Please try again in a little while."
                                        ],
                                        okayButtonText: "Okay"
                                    },
                                });
                            })
                    }, 1000);
                }
            },
        });
    }

    const showRemoveLabel = ({ event, site, labelUuid }) => {
        event.preventDefault();

        props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                "show": true,
                "type": "confirmation",
                "title": "Confirm Remove Label",
                "message": [
                    `Are you sure you would like to remove the label "${labelsData.labelsMap[labelUuid].name}" from the application "${site.name}"?`,
                    "‏‏‎ ‎",
                    labelsData.labelsMap[labelUuid].type == "user" ? "This will remove the label from your personal view only." : "This will remove the label from the entire team.",
                ],
                yesButtonText: "Yes",
                noButtonText: "No",
                onYes: (onClose) => {
                    props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            "show": true,
                            "type": "processing",
                            "title": "Removing Label",
                            "message": [
                                "This might take a few seconds.",
                                "You will be shown a confirmation screen once completed."
                            ]
                        },
                    });

                    setTimeout(() => {
                        axiosBackend({
                            method: "DELETE",
                            url: `/apps/labels`,
                            data: {
                                label_uuid: labelUuid,
                                site_uuid: site.uuid,
                            }
                        })
                            .then((response) => {
                                props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "success",
                                        title: "Success",
                                        message: [
                                            `The label "${labelsData.labelsMap[labelUuid].name}" was successfully removed from the application "${site.name}".`,
                                        ],
                                        okayButtonText: "Okay",
                                    },
                                });

                                revalidator.revalidate();
                            })
                            .catch((err) => {
                                console.error(err);

                                let messages = [
                                    "Due to an error, we were unable to remove the label you had selected",
                                    "‏‏‎ ‎",
                                    "Please try again in a little while."
                                ];

                                if (err.response.data.messages) {
                                    messages = err.response.data.messages;
                                } 

                                props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "error",
                                        title: "Could not remove label",
                                        message: messages,
                                        okayButtonText: "Okay"
                                    },
                                });
                            })
                    }, 1000);
                }
            },
        });
    }

    const renderTile = ({ item, labelUuid }) => {
        let labelType = "live";

        if (typeof item.status == "string") {
            switch (item.status.toLowerCase()) {
                case "draft":
                    labelType = "draft";
                    break;

                case "offline":
                case "maintenance":
                    labelType = "danger";
                    break;
            }
        }

        return <>
            <Link key={item.uuid} className="col-lg-3 mb-2 pb-2" to={`/app/${item.uuid}/`}>
                <div className="card">
                    <div className="widget-header bg-primary">
                        <ImageWithFallback
                            className="card-img-top widget-bg img-responsive"
                            fallbackSrc="/img/app.jpg"
                            src={item.image}
                            alt={item.name}
                        />
                    </div>
                    <div className="card-body text-center">
                        <h4 className="mar-no text-main">{item.name}</h4>
                        <p className="text-muted mar-no">{item.type}</p>

                        <div className="dropdown show pointer float-right" style={{ "position": "absolute", "right": "1%", "top": "1%" }}>
                            <button aria-label="Dropdown Options" onClick={(event) => event.preventDefault()} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="btn btn-outline btn-sm text-main">
                                <i className="fas fa-ellipsis-v"></i>
                            </button>

                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ "zIndex": 10000 }}>
                                <span
                                    className="dropdown-item"
                                    onClick={(event) => showAddLabel({ event, site: item })}
                                >
                                    <i className="fas fa-plus"></i>&nbsp;Add New Label
                                </span>

                                {labelUuid && <span
                                    className="dropdown-item"
                                    onClick={(event) => showRemoveLabel({ event, site: item, labelUuid })}
                                >
                                    <i className="fas fa-trash"></i>&nbsp;Remove Label
                                </span>}
                            </div>
                        </div>
                    </div>

                </div>
            </Link>
        </>;
    }

    const lablesKeys = Object.keys(labelsData.sitesMap);
    let lastLabelType = "";

    return (
        <>
            <ReactJoyride
                // scrollToFirstStep={true}
                callback={handleJoyrideCallback}
                run={showTour}
                stepIndex={stepIndex}
                steps={steps}
                continuous={true}
                showSkipButton={true}
                showProgress={true}
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                }}
            />



            <div className="row">
                <div className="col-lg-12 pb-2">

                    <button id="toggle-help" className="btn btn-gray" onClick={toggleHelp}>
                        <span className="far fa-question-circle fa-lg"></span>
                    </button>
                </div>

                <div className="col-sm-12">
                    <div className="form-group">
                        <input
                            id="search-applications"
                            autoFocus={true}
                            onChange={search}
                            placeholder={props.searchText ? props.searchText : "Type to search your applications"}
                            className="form-control input-lg search"
                            type="text"
                        />
                    </div>

                </div>
            </div>

            <div className="row " id="applications-list">

                {list == undefined || !Array.isArray(list) || list.filter(item => !item.hidden).length == 0 ?
                    <div className="col-sm-12 pb-3 pt-3">{props.noDataMessage || "No applications found"}</div>
                    :
                    undefined
                }

                {lablesKeys.sort((a, b) => {
                    if (labelsData.labelsMap[a].type == "user") {
                        return -1;
                    } else {
                        return 1;
                    }
                }).map((labelUuid) => {
                    const sites = labelsData.sitesMap[labelUuid];

                    const filteredList = list.filter((item) => sites.includes(item.uuid) && !item.hidden);

                    let newLabelType = false;
                    let labelType = "";

                    if (labelsData.labelsMap[labelUuid].type == "user") {
                        labelType = "";
                    } else {
                        labelType = "live";
                    }

                    if (lastLabelType != labelsData.labelsMap[labelUuid].type) {
                        lastLabelType = labelsData.labelsMap[labelUuid].type;
                        newLabelType = true;
                    }

                    if (filteredList.length == 0) {
                        return;
                    }

                    return <>
                        {newLabelType && <div className="col-sm-12 mb-2">
                            <h5 className="mar-no text-main border-bottom pb-2">
                                {labelsData.labelsMap[labelUuid].type == "user" ? "Your Personally Labeled Applications" : "Team Labeled Applications"}
                            </h5>
                        </div>}

                        <div className="col-sm-12 mb-2">
                            <span className={`label label-sm label-${labelType}`}>{labelsData.labelsMap[labelUuid].name}</span>
                        </div>

                        {filteredList.map((item) => {
                            return renderTile({ item, labelUuid });
                        })}
                    </>;
                })}

                {lablesKeys.length > 0 && <div className="col-sm-12"></div>}

                {!!list && Array.isArray(list) && list.filter((item) => !item.hidden && !labelsData.sitesToSkip.includes(item.uuid)).map((item, index) => {
                    return <>
                        {index == 0 && <div className="col-sm-12 mb-2">
                            <h5 className="mar-no text-main border-bottom pb-2">
                                Unlabeled Applications
                            </h5>
                        </div>}

                        {renderTile({ item, labelUuid: null })}
                    </>;

                })}


                <div className="col-sm-3" id='create-new-application'>
                    <Link to="/create-new-application" className="text-inherit">
                        <div className="card">
                            <div className="widget-header d-flex align-content-center justify-content-center flex-wrap p-0">
                                <i className="fas fa-plus fa-4x" />
                            </div>
                            <div className="card-body text-center">
                                <h4 className="mar-no text-main">Create New Application</h4>
                                <p className="text-muted mar-no">&nbsp;</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default connect(mapStateToProps)(Landing);